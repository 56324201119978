.header-container {
  height: 80px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 35px;
  left: 0;
  padding: 5px 40px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
  z-index: 8;
}

.brand-container {
  margin: 0;
  width: 140px;
}

.brand-container img {
  width: 60px;
}

.menu {
  margin: 0;
  box-sizing: border-box;
  display: flex;
}

.link {
  color: #5f5b5b;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 30px;
  padding: 2px 0;
  display: flex;
  transition: all 0.2s ease;
}

.link:hover {
  opacity: 0.8;
}

.hamburguer {
  display: none;
}

.btn-drop-down {
  color: #5f5b5b;
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  margin-left: 30px;
  padding: 2px 0;
  display: flex;
  transition: all 0.3s ease;
  border: none;
  background-color: #fff;
  cursor: pointer;
  outline: none;
}

.link-style {
  border: none;
  outline: none;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  font-size: 0.875rem;
  cursor: pointer;
  line-height: 16px;
  transition: all 0.2s ease;
}

.icon-color {
  padding-left: 4px;
  color: #5f5b5b;
  position: relative;
  top: 2px;
}

.link-style:hover {
  opacity: 0.8;
}

.drop-down-style {
  position: absolute;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  top: 81px;
  border-radius: 2px;
  width: 400px;
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.2s ease;
}

.column {
  padding: 10px 0;
  width: 50%;
}

.column p {
  text-align: center;
  padding: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  color: #5f5b5b;
  letter-spacing: -0.02em;
}

.euRsnu {
  outline: none;
}

@media (min-width: 299px) and (max-width: 767px) {
  .header-container {
    padding: 5px 20px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease;
    position: inherit;
    left: 0;
    top: 115px;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgb(214, 214, 214);
    padding-top: 10px;
    overflow: hidden;
    transform: translateX(-100%);
  }

  .showMenu {
    transform: translateX(0%);
    top: 115px;
  }

  .link {
    width: 100%;
    margin: 0;
    justify-content: flex-end;
    padding: 15px 35px 15px 5px;
    box-sizing: border-box;
  }

  .link:hover {
    border-bottom: none;
  }

  .hamburguer {
    display: inline;
    margin: 0;
  }

  .btn-drop-down {
    margin: 0;
  }

  .drop-down-responsive {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    padding: 15px 35px 15px 15px;
    box-sizing: border-box;
  }

  .drop-down-style {
    width: 100%;
    left: 0;
    top: 100px;
    flex-direction: column-reverse;
    box-shadow: none;
  }

  .column {
    width: 100%;
  }
}
