.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 35px;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 40px;
  min-height: 40px;
  opacity: 1;
  cursor: pointer;
  z-index: 999;
  margin: -30px;
}

.img-carousel {
  width: 100px;
}

.img-carousel img {
  width: 100px;
  max-width: 150px;
}
