.pop-up-container{
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #00000085;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;

  .pop-up-box{
    position: absolute;
    background-color: #fff;
    width: 100%;
    max-width: 800px;
    height: 450px;
    display: flex;
    justify-content: space-between;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .pu-text{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 20px;
      position: relative;
      
      h3{
        margin: 0;
        padding: 5px 0 8px 0;
        text-transform: uppercase;  
        font-size: 32px;
        font-weight: 900; 
      }

      h4{
        margin: 0;
        color: #14348e;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 900;
      }

      p{
        font-size: 16px;
        line-height: 22px;
      }

      .download-btn{
        text-decoration: none;
        margin-top: 30px;
        background-color: #14348e;
        color: #fff;
        width: 100%;
        text-align: center;
        border-radius: 50px;
        padding: 12px 0;
        font-weight: 600;
        font-size: 14px;
        transition: 0.3s;
        border: 1px solid;
        &:hover{
          background: #062e9b;
          border-color: #062e9b;
        }
      }

      .close{
        font-weight: 600;
        font-size: 21px;
        position: absolute;
        top: 20px;
        left: 20px;
        cursor: pointer;
      }
    }

    .pu-img{
      width: 50%;
      background-image: url('../assets/cortina.jpg');
      background-position: center;
    }
  }
}