.alert {
  width: 100%;
  position: fixed;
  height: 35px;
  background-color: #1a1a1a;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  box-sizing: border-box;
}

.message {
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  box-sizing: border-box;
}

.number {
  font-family: "Lato", sans-serif;
  color: #fff;
  margin: 0;
  margin-left: 5px;
  font-size: 12px;
  letter-spacing: 0.2px;
}

@media (min-width: 299px) and (max-width: 767px) {
  .message {
    font-size: 12px;
    text-align: center;
    padding: 8px 5px;
    line-height: 0.8rem;
  }
}
