.models {
  width: 100%;
  max-width: 1200;
  height: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.models h4 {
  margin-top: 130px;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-size: 2rem;
  text-align: center;
}

.models-container {
  margin-top: 80px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.product-model {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 30px;
}

.img-box {
  width: 20%;
  height: 180px;
  margin: 0;
  text-align: center;
}

.img-box img {
  width: auto;
  height: auto;
}

.title-description {
  margin: 0 0 60px 15px;
  width: 80%;
}

.title-description h5 {
  padding: 15px;
  margin-bottom: 5px;
  background: #eaeaea;
  opacity: 0.8;
  font-size: 1rem;
  letter-spacing: 0.02em;
  box-sizing: border-box;
}

.title-description p {
  padding: 15px 15px;
  letter-spacing: 0.02em;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.drop-down-file {
  text-align: end;
  margin-top: 15px;
  position: relative;
}

.btn-ficha {
  padding: 7px 20px;
  background-color: #14358ee0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  position: relative;
  width: 200px;
}

.file-link {
  display: flex;
  flex-direction: column;
  width: 200px;
  position: absolute;
  right: 0;
  background: #eaeaea;
}

.file {
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
}

.file:hover {
  background-color: #14358e1e;
}

i {
  color: #fff;
}

.notFile {
  display: none;
}

@media (min-width: 299px) and (max-width: 767px) {
  .product-model {
    flex-direction: column;
  }

  .img-box {
    width: 100%;
    text-align: center;
  }

  .title-description {
    margin: 0;
    margin-bottom: 50px;
    width: 100%;
  }

  .title-description h5 {
    padding: 15px;
    margin-bottom: 5px;
    background: #eaeaea;
    opacity: 0.8;
    font-size: 1rem;
    letter-spacing: 0.02em;
  }

  .title-description p {
    padding: 5px 15px;
    letter-spacing: 0.02em;
    font-size: 0.9rem;
  }

  .drop-down-file {
    text-align: center;
    margin-top: 30px;
  }

  .file-link {
    right: 87px;
  }
}
