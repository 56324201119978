.products {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.products h3 {
  margin-top: 180px;
  font-size: 2.375rem;
  text-transform: uppercase;
  letter-spacing: 0.02em;
}

.products-container {
  margin-top: 50px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
}

.products-container p {
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  margin: 0 0 10px 0;
}

.products-container a {
  text-decoration: none;
  padding: 15px;
  font-size: 1.125rem;
  font-weight: 500;
}

.link-product {
  background: #14348e;
  color: #fff;
  width: 100%;
  margin-bottom: 10px;
  box-sizing: border-box;
  transition: 0.4s;
  opacity: 0.8;
}

.link-product:hover {
  background: #14348e;
  color: #fff;
  opacity: 1;
}
