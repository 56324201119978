* {
  margin: 0 auto;
  font-family: "Raleway", sans-serif;
  color: #1e2d3b;
  box-sizing: border-box;
}

h2 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.375rem;
  letter-spacing: 0.02em;
}

button {
  cursor: pointer;
  outline: none;
}

.line {
  width: 100px;
  height: 6px;
  background: #14348e;
  margin-top: 12px;
  border: none;
}

.whatsapp {
  position: fixed;
  z-index: 999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  top: 80%;
  right: 4%;
}

.btn {
  margin-top: 30px;
  background: #14348e;
  border-color: #14348e;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.01em;
  border: none;
  padding: 15px 60px;
  cursor: pointer;
  outline: none;
}

.btn:hover {
  background: #062e9b;
  border-color: #062e9b;
}

.top {
  height: 100vh;
  margin-bottom: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.top img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(60%);
  box-sizing: border-box;
}

.cta {
  width: 100%;
  max-width: 1200px;
  position: relative;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
}

.cta h1 {
  font-size: 3rem;
  letter-spacing: -0.03em;
  font-weight: 800;
  text-transform: uppercase;
  color: #ffffff;
  padding-bottom: 15px;
}

.ticks {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.cta p {
  font-size: 1.375rem;
  letter-spacing: -0.03em;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  padding-right: 20px;
}

.products {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 110px;
  text-align: center;
  box-sizing: border-box;
}

.img-links-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.v-link {
  width: 48%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;
}

.v-link p {
  position: absolute;
  font-size: 1.625rem;
  font-weight: 900;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
  letter-spacing: -0.03em;
  transition: opacity 0.3s linear;
}

.v-link img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  filter: brightness(60%);
  transition: filter 0.15s linear;
  transition: transform 0.3s linear;
  box-sizing: border-box;
}

.v-link img:hover {
  transform: scale(1.2);
  filter: brightness(90%);
}

.about-us {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-bottom: 110px;
  background: #14348e;
  box-sizing: border-box;
  overflow: hidden;
}

#nosotros {
  position: absolute;
  top: -60px;
}

.about-us-text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.line-content {
  display: flex;
  width: 100%;
  max-width: 500px;
  align-items: flex-start;
}

.line-white {
  width: 100px;
  height: 6px;
  background: #fff;
  margin: 16px 0 0 0;
  border: none;
}

.about-us-text h2 {
  color: #fff;
  width: 100%;
  max-width: 500px;
}

.about-us-text p {
  color: #ffffff;
  margin-top: 45px;
  width: 100%;
  max-width: 500px;
  text-align: justify;
}

.about-us-img {
  width: 50%;
  height: 600px;
}

.about-us-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clinets {
  width: 100%;
  max-width: 1000px;
  text-align: center;
  margin-bottom: 110px;
  position: relative;
}

#clientes {
  position: absolute;
  top: -120px;
}

.clients-carrusel {
  margin-top: 90px;
}

.contact {
  width: 100%;
  height: auto;
  max-width: 900px;
  text-align: center;
  margin-bottom: 110px;
  position: relative;
}

#contacto {
  position: absolute;
  top: -120px;
}

.map {
  width: 100%;
  height: 350px;
  margin-top: 50px;
}

iframe {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border: none;
}

.gm-style {
  display: none;
}

.contact-container {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.conact-form {
  width: 60%;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-container input {
  width: 100%;
  padding: 20px 0 5px 5px;
  margin-bottom: 10px;
  color: #5f5b5b;
  border: none;
  border-bottom: 2px solid #1e2d3b;
  box-sizing: border-box;
  font-style: italic;
  font-size: 0.9rem;
  outline: none;
}

.form-container textarea {
  width: 100%;
  height: 70px;
  padding: 20px 0 5px 5px;
  margin-bottom: 10px;
  color: #5f5b5b;
  border: none;
  border-bottom: 2px solid #1e2d3b;
  box-sizing: border-box;
  font-style: italic;
  font-size: 0.9rem;
  outline: none;
}

.form-container p {
  margin: 0;
  text-align: -webkit-left;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
}

.contact-info {
  width: 40%;
  box-sizing: border-box;
  padding: 0 50px;
}

.contact-info h3 {
  margin: 0;
  text-align: -webkit-left;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-info p {
  font-size: 0.875rem;
  text-align: justify;
  padding-bottom: 15px;
  font-family: "Lato", sans-serif;
  color: #5f5b5b;
}

.interno {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.interno p {
  padding: 0;
  justify-content: center;
  margin: 0;
  margin-left: 10px;
}

.footer {
  text-align: center;
  border-top: 1px solid #1a1a1a;
  padding: 30px 0;
  background-color: #1a1a1a;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.work-with-us {
  width: 100%;
  background: #ececec;
}
.work-with-us-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 80px 20px;
}

.work-with-us-container h3 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 2.375rem;
  letter-spacing: 0.02em;
  padding-bottom: 10px;
}

.work-with-us-container p {
  font-size: 21px;
  line-height: 0px;
  letter-spacing: 0.02em;
}
.form-work {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.form-work input {
  width: 100%;
  max-width: 350px;
  margin: 0;
  margin-bottom: 12px;
  color: #5f5b5b;
  border: none;
  border-bottom: 2px solid #1e2d3b;
  padding: 5px 0 5px 5px;
  background: #ececec;
  outline: none;
}

.form-work input[type="file"] {
  display: none;
}

.file-upload {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  font-size: 12px;
  line-height: 0px;
  letter-spacing: 0.02em;
  margin-top: 6px;
  margin-bottom: 20px;
  padding-left: 5px;
}
.file-upload:hover {
  text-decoration: underline;
}
.file-upload img {
  margin: 0;
  padding-right: 3px;
}

.form-work button {
  width: 120px;
  height: 35px;
  border-radius: 25px;
  border: none;
  background-color: #14348e;
  color: #fff;
  margin: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 299px) and (max-width: 767px) {
  .cta h1 {
    font-size: 2.5rem;
    padding: 8px;
  }

  .ticks {
    flex-direction: column;
    padding: 20px 5px 0 5px;
  }

  .ticks p {
    padding: 0;
    font-size: 1rem;
  }

  .img-links-container {
    flex-direction: column;
    padding: 0 10px;
  }

  .v-link {
    width: 100%;
    height: 200px;
    margin-bottom: 25px;
  }

  .about-us {
    flex-direction: column;
  }

  .about-us-text {
    width: 100%;
    padding: 40px 20px;
  }

  .about-us-text h2 {
    margin: 0;
  }

  .about-us-text p {
    margin: 25px 0 0 0;
    width: 100%;
    font-size: 14px;
  }

  .about-us-img {
    display: block;
    width: 100%;
    height: 350px;
  }

  .contact-container {
    flex-direction: column;
  }

  .conact-form {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .form-container {
    display: flex;
    flex-direction: column;
  }

  .contact-info {
    width: 100%;
    padding: 20px;
    margin-top: 50px;
  }

  .contact-info p {
    font-size: 0.9rem;
  }

  .line-content {
    margin: 0;
  }
}
